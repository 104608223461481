<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          :color="color">
        <v-icon
        >
          mdi-email-sync
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary">
        Dokumentenupload Link erneut senden
      </v-card-title>
      <v-card-text class="mt-5">
        Sie haben hier die Möglichkeit, den "Link erneut senden"-Button zu nutzen, um dem Kunden die Dokumentenupload-E-Mail erneut zuzusenden.
        Der Kunde kann dann den erhaltenen Link erneut 48H nutzen, um die benötigten Dokumente hochzuladen.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            plain
            @click="resentUploadLink()"
        >
          Link erneut senden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";

export default {
  name: "ResentUploadLink",
  props: {
    controllerURL: {
      required: true
    },
    caseId: {
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    async resentUploadLink() {
      await sessionHandler();
      await this.$http.patch(this.controllerURL + "/" + this.caseId + "/ResendUploadLink" , {}, {
        mode: requestMode(),
        headers: {
          'Accept': 'application/json',
          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
        },
      })
          .then(() => {
            this.dialog = false;
            showAlert("Der Dokumentenupload Link wurde erneut an den Kunden versand.", "success");
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    noteRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => v.length <= 8000 || "Notiz darf nicht länger als 8000 Zeichen sein"
      ];
    },
  },
}
</script>

<style scoped>

</style>
