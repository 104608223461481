<template>
  <v-card>
    <v-card-title>
      {{ openCase.customerName }}
      {{ openCase.customerLastname }}
      <v-spacer></v-spacer>
      <v-btn :href="'tel:' + openCase.customerMobileNumber" color="primary" icon>
        <v-icon>mdi-phone</v-icon>
      </v-btn>
      <view-notes-dialog v-bind:controllerURL="controllerURL" v-bind:currentCase="openCase" />
      <!--
      <add-note-to-case
          v-bind:controllerURL="controllerURL"
          v-bind:currentCase="openCase"
          v-on:updateNotes="fixNotes()"
      />
      -->
      <view-linked-events-dialog ref="LinkedEventsDialog" v-bind:controllerURL="controllerURL" v-bind:currentCase="openCase" />
      <resent-upload-link v-bind:controllerURL="controllerURL" v-bind:caseId="openCase.id" />
      <add-to-m-p-u v-on:updateCases="getCases()" v-if="admin" v-bind:currentCase="openCase" />
      <m-p-u-file-upload  v-bind:caseId="openCase.id"  v-bind:controllerURL="controllerURL" v-on:updateCases="getCases()"  />

    </v-card-title>
   <v-card-text>
     <v-progress-linear rounded class="mb-7" height="25" color="primary" v-model="appointmentCounter">
       <strong v-if="openCase.numberOfAssignedAppointments  !== openCase.numberOfBookedAppointments">{{ openCase.numberOfAssignedAppointments }}/{{ openCase.numberOfBookedAppointments }} Terminen bereits gebucht</strong>
       <strong v-else>Terminkontingent bereits ausgeschöpft!</strong>
     </v-progress-linear>
     <v-card color="primary" flat>
       <v-card-title>Kundendaten</v-card-title>
       <v-card-subtitle>
         <span v-if="openCase.customerMailAddress">{{ openCase.customerMailAddress }}</span><br v-if="openCase.customerMailAddress" />
         <span v-if="openCase.customerMobileNumber">{{ openCase.customerMobileNumber }}</span><br v-if="openCase.customerMobileNumber" /><br />
         <span v-if="openCase.customerStreet">{{ openCase.customerStreet }} {{ openCase.customerStreetnumber }}</span><br v-if="openCase.customerStreet" />
         <span v-if="openCase.customerCity">{{ openCase.customerZipCode }} {{ openCase.customerCity }}</span><br v-if="openCase.customerCity" />
         <span v-if="openCase.contractNumber" >Versicherungsnummer:  {{ openCase.contractNumber }}</span>
       </v-card-subtitle>
     </v-card>
   </v-card-text>
    <div>
      <v-card-text>
        <v-card flat color="primary">
          <div v-if="openCase.reason">
            <v-card-title>Grund</v-card-title>
            <v-card-text>
              <view-more v-bind:text="openCase.reason" />
            </v-card-text>
          </div>

        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-dialog
                v-model="closeDialog"
                max-width="600"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon
                          left
                      >
                        mdi-lock
                      </v-icon>
                      Fall schließen
                    </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar
                      color="primary"
                      dark
                  >Fall schließen?
                  </v-toolbar>
                  <v-card-text class="mt-2">
                    <p>Wollen Sie den Fall wirklich schließen?</p>
                    <p>Die Dokumente werden dadruch gelöscht und der Link des Kunden wird ungültig!</p>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                        text
                        @click="dialog.value = false"
                    >Abbruch
                    </v-btn>
                    <v-btn color="error" @click="close(openCase.id)">Schließen</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <m-p-u-file-downloads v-bind:dataTypeName="dataTypeName" v-bind:id="openCase.id" v-bind:controllerURL="controllerURL" v-bind:dataType="dataType" />
    </div>
  </v-card>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import ViewNotesDialog from "@/components/dialogs/notes/ViewNotesDialog";
// import AddNoteToCase from "@/components/dialogs/notes/AddNoteToCase";
import MPUFileDownloads from "@/components/MPU/MPUFileDownloads";
import ViewLinkedEventsDialog from "@/components/dialogs/event/ViewLinkedEventsDialog";
import ResentUploadLink from "@/components/dialogs/ResentUploadLink";
import reactiveStorage from "@/plugins/reactiveStorage";
import ViewMore from "@/components/ViewMore";
import AddToMPU from "@/components/dialogs/mpu/AddToMPU";
import MPUFileUpload from "@/components/MPU/MPUFileUpload";

export default {
  name: "OpenCaseCard",
  components: {
    MPUFileUpload,
    AddToMPU,
    ViewMore,
    ResentUploadLink, ViewLinkedEventsDialog, MPUFileDownloads  /*, AddNoteToCase */, ViewNotesDialog},
  props: {
    openCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
    dataType: {
      required: true,
    },
    dataTypeName: {
      required: true
    },
    allowDataEdit: {
      required: true
    },
  },
  data: () => ({
    closingReport: "",
    appointmentCounter: 0,
    dialog: false,
    closeDialog: false,
    tooltip: false,
    admin: reactiveStorage.user.roles.Admin || reactiveStorage.user.roles.Moderator,
  }),
  mounted() {
    this.closingReport = this.openCase.closingReport ? this.openCase.closingReport : "";
    if (this.closingReport === "") {
      if (localStorage.getItem('OpenMPUCaseCardCase' + this.openCase.id)) {
        this.recoverData()
      }
    }
    this.closingCustomerMessage = this.openCase.closingCustomerMessage ? this.openCase.closingCustomerMessage : "";
    this.appointmentCounter = Math.ceil(this.openCase.numberOfAssignedAppointments / this.openCase.numberOfBookedAppointments * 100)
  },
  methods: {
    clearRecoverData() {
      localStorage.setItem('OpenMPUCaseCardCase' + this.openCase.id, "")
    },
    recoverData() {
      this.closingCustomerMessage = localStorage.getItem('OpenMPUCaseCardCase' + this.openCase.id)
    },
    saveToLocalStorage(){
      localStorage.setItem('OpenMPUCaseCardCase' + this.openCase.id, this.closingReport)
    },
    async close(openCaseId) {
      await this.save(openCaseId)
          await sessionHandler();
          await this.$http
              .patch(
                  this.controllerURL + "/" + openCaseId,
                  {
                    "closingReport": this.openCase.closingReport,
                    "closed": !this.openCase.closed
                  }
                  , {
                    mode: requestMode(),
                    headers: {
                      'Accept': 'application/json',
                      'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                    },
                  }
              )
              .then((response) => {
                if (response.status === 200) {
                  this.getCases();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 401) {
                    showAlert(i18n.t('warning.unauthorised'), "warning");
                  }
                } else {
                  showAlert(i18n.t('error.api.undefined') + "Calendar-gE2", "error");
                }
              })
              .finally(() => {
                this.isLoadingBill = false;
              })
      },
    async save(openCaseId) {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "/" +  openCaseId,
              {
                "closingReport": this.closingReport,
                "closed": this.openCase.closed
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.clearRecoverData();
              this.getCases();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2", "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
            this.closeDialog = false;
          })
    },
    getCases() {
      this.$emit("getCases")
    },
  },
  computed: {}
}
</script>

<style scoped>

</style>
