<template>
  <v-card>
    <v-card-title>
      {{ closedCase.customerName }}
      {{ closedCase.customerLastname }}
      <v-spacer></v-spacer>
      <v-btn :href="'tel:' + closedCase.customerMobileNumber" color="error" icon>
        <v-icon>mdi-phone</v-icon>
      </v-btn>
      <view-notes-dialog v-bind:color="'error'"  v-bind:controllerURL="controllerURL" v-bind:currentCase="closedCase" />
    </v-card-title>
    <v-card-text>
      <v-progress-linear rounded class="mb-7" height="25" color="error" v-model="appointmentCounter">
        <strong v-if="closedCase.numberOfAssignedAppointments  !== closedCase.numberOfBookedAppointments">{{ closedCase.numberOfAssignedAppointments }}/{{ closedCase.numberOfBookedAppointments }} Terminen bereits gebucht</strong>
        <strong v-else>Terminkontingent bereits ausgeschöpft!</strong>
      </v-progress-linear>
      <v-card color="error" flat>
        <v-card-title>Kundendaten</v-card-title>
        <v-card-subtitle>
          <span v-if="closedCase.customerMailAddress">{{ closedCase.customerMailAddress }}</span><br v-if="closedCase.customerMailAddress" />
          <span v-if="closedCase.customerMobileNumber">{{ closedCase.customerMobileNumber }}</span><br v-if="closedCase.customerMobileNumber" /><br />
          <span v-if="closedCase.customerStreet">{{ closedCase.customerStreet }} {{ closedCase.customerStreetnumber }}</span><br v-if="closedCase.customerStreet" />
          <span v-if="closedCase.customerCity">{{ closedCase.customerZipCode }} {{ closedCase.customerCity }}</span><br v-if="closedCase.customerCity" />
          <span v-if="closedCase.contractNumber" >Versicherungsnummer:  {{ closedCase.contractNumber }}</span>
        </v-card-subtitle>
      </v-card>
    </v-card-text>
    <div>
      <v-card-text>
        <v-card flat color="error">
          <div v-if="closedCase.reason">
            <v-card-title>Grund</v-card-title>
            <v-card-text>
              <view-more v-bind:text="closedCase.reason" />
            </v-card-text>
          </div>

        </v-card>
      </v-card-text>
      <v-card-text>
        <v-card flat color="error">
          <v-card-title>
            Geschlossen am {{ closedCase.updatedAt }} {{ closedCase.updatedBy === closedCase.createdBy ? "" : " von " + closedCase.updatedBy }}
          </v-card-title>
        </v-card>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getAragURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import reactiveStorage from "@/plugins/reactiveStorage";
import ViewNotesDialog from "@/components/dialogs/notes/ViewNotesDialog";
import ViewMore from "@/components/ViewMore";

export default {
  name: "ClosedCaseCard",
  props: {
    closedCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
  },
  data: () => ({
    admin: reactiveStorage.user.roles.Admin,
    description: document.description,
    dialog: false,
    closingCustomerMessage: "",
    appointmentCounter: 0,
  }),
  components: {ViewMore, ViewNotesDialog },
  methods: {
    async close(documentId) {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "/" + documentId,
              {
                "closingReport": this.document.closingReport,
                "closed": !this.document.closed
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.getDocuments();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE2" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },
    updateCases() {
      this.$emit("getCases")
    },
    async sendMail(documentId) {
      await sessionHandler();
      await this.$http
          .patch(
              getAragURL(true) + "/" + documentId,
              {
                "closingCustomerMessage": this.closingCustomerMessage,
              }
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.updateCases();
              showAlert("Die E-Mail wurde erfolgreich versendet", "success");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "CloseCaseCard-sendMail-1", "error");
            }
          })
          .finally(() => {
            this.dialog = false
          })
    },
  },
  computed: {},
  mounted() {
    this.appointmentCounter = Math.ceil(this.closedCase.numberOfAssignedAppointments / this.closedCase.numberOfBookedAppointments * 100)
  }
}
</script>

<style scoped>

</style>
