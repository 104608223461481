<template>
  <div>
    <!-- DONT CHANGE insurance cuz of fix in CaseView!!-->
    <m-p-u-case-view v-bind:dataTypeName="'MPU'" v-bind:insurance="'MPU'" v-bind:permission="'MPU'" v-bind:rebindPermission="'MPU'" v-bind:dataType="'MPU'" v-bind:name="'MPU Fälle'" v-bind:controllerURL="controllerURLMPU" v-bind:allowDataEdit="false"/>
  </div>
 </template>

<script>
import MPUCaseView from "@/components/MPU/MPUCaseView";
import {getApiURL} from "@/configBuilder";
export default {
  name: "DokumentencheckView",
  components: { MPUCaseView },
  data: () => ({
    controllerURLMPU: getApiURL("MPUData"),
  }),
}
</script>

<style scoped>

</style>
